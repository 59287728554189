<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />

    <GenericPageMovie v-else-if="!pending && !page" :movie />
  </div>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
}

defineProps<Props>()

const { pending, page, components } = await useCmsPageFetch()
</script>
