<template>
  <slot :movie="movie" :pending="pending" />
</template>

<script setup lang="ts">
import type { FetchMovieDataForMoviePageQueryVariables } from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchMovieDataForMoviePageQueryVariables
  throwError?: boolean
}

const COMPONENT_NAME = 'MovieFetch'
const props = withDefaults(defineProps<Props>(), {
  throwError: false,
})

const { t } = useI18n()

const { data, pending, execute } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.fetchKey),
  () => GqlFetchMovieDataForMoviePage({ ...props.fetchParams }),
  { immediate: false }
)

await execute()

if (!data.value?.movie?.id && props.throwError) {
  showError({
    statusCode: 404,
    statusMessage: t('errors.itemNotFound', { item: t('item.movie') }),
  })
}

const movie = computed(() => data.value?.movie)

defineOptions({
  name: COMPONENT_NAME,
})
</script>

<i18n>
de:
  errors:
    itemNotFound: "{item} nicht gefunden"
  item:
    movie: "Film"
es:
  errors:
    itemNotFound: "{item} no encontrado"
  item:
    movie: "Película"
</i18n>
