<template>
  <MovieFetch
    :fetch-key="`movie-page-provider-${urlSlug}`"
    :fetch-params="{ urlSlug }"
    :throw-error="true"
  >
    <template #default="{ movie, pending }">
      <NuxtPage v-if="!pending && movie" :movie="movie" />
    </template>
  </MovieFetch>
</template>

<script setup lang="ts">
import { useRouteParams } from '@vueuse/router'

const urlSlug = useRouteParams('movieSlug', '', { transform: String })

defineI18nRoute({
  paths: {
    en: '/movie/[movieSlug]',
    de: '/film/[movieSlug]',
    es: '/pelicula/[movieSlug]',
  },
})

defineOptions({
  name: 'PageMovieMovieSlug',
})
</script>
